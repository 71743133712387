import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';

const axiosInstance = axios.create();

axiosInstance.defaults.headers.common['x-api-key'] = process.env.REACT_APP_API_KEY;

export const mock = new AxiosMockAdapter(axiosInstance, { delayResponse: 0 });

export default axiosInstance;
