import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as mailReducer } from '../slices/mail';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  kanban: kanbanReducer,
  mail: mailReducer
});

export default rootReducer;
