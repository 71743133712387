import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Hidden, Typography } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import {
  Home,
  Description,
  AssignmentInd,
  BarChart,
  LocalShipping,
  Settings,
  Assessment
} from '@material-ui/icons';
import logo  from "src/images/logo.webp";

const sections = [
  {
    //need to make path dynimic
    title: 'General',
    items: [
      {
        title: 'Dashboard',
        path: '/',
        icon: <Home fontSize="small" />,
      },
      {
        title: 'Services',
        path: '/services',
        icon: <LocalShipping fontSize="small" />,
      },
      {
        title: 'Vendors',
        path: '/vendors',
        icon: <AssignmentInd fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/vendors',
            icon: <AssignmentInd fontSize="small" />
          },
          {
            title: 'Express Users',
            path: '/vendors/users',
            icon: <AssignmentInd fontSize="small" />
          },
          {
            title: 'Create Express User',
            path: '/vendors/users/create',
            icon: <AssignmentInd fontSize="small" />
          },

        ]
      },
      {
        title: 'Orders',
        icon: <Description fontSize="small" />,
        path: '/orders',
        children: [
          {
            title: 'List',
            path: '/orders',
            icon: <Description fontSize="small" />
          },
          {
            title: 'Create Order',
            path: '/orders/create',
            icon: <Description fontSize="small" />
          },
          {
            title: 'Import Orders',
            path: '/orders/import',
            icon: <Description fontSize="small" />
          },
          {
            title: 'Import Square Orders',
            path: '/orders/squareImport',
            icon: <Description fontSize="small" />
          },
        ]
      },
      {
        title: 'Invoices',
        path: '/reports',
        icon: <Description fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/invoices/list',
            icon: <Description fontSize="small" />
          },
        ]
      },
      {
        title: 'Reports',
        path: '/reports',
        icon: <BarChart fontSize="small" />,
        children: [
          {
            title: 'Overview',
            path: '/reports',
            icon: <Assessment fontSize="small" />
          },
          {
            title: 'List',
            icon: <Description fontSize="small" />,
            path: '/reports/list',
          }
        ]
      },
      {
        title: 'Settings',
        icon: <Settings fontSize="small" />,
        path: '/settings'
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pb: 3,
              pt: 6
            }}
          >
            <RouterLink to="/kit">
              <img
                src={ logo } 
                alt="logo"
                style={{ width: '200px' }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            {/* <RouterLink to="/account"> */}
            <Avatar
              src={user.avatar}
              sx={{
                // cursor: 'pointer',
                height: 48,
                width: 48
              }}
            />
            {/* </RouterLink> */}
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.email}
              </Typography>
              {/* <Typography
                color="textSecondary"
                variant="body2"
              >
                Your plan:
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/kit/pricing"
                >
                  {user.plan}
                </Link>
              </Typography> */}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
