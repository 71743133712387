import useAuth from "../../hooks/useAuth";
import { useIdleTimer } from "react-idle-timer";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useState, useRef } from "react";
import { useSnackbar } from "notistack";

function UserActivity() {
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // Idle
  const [open, setOpen] = useState(false);
  const sessionTimeoutRef = useRef(null);

  const handleOnIdle = () => {
    setOpen(true);
    sessionTimeoutRef.current = setTimeout(() => handleOnTimeout(), 60 * 1000);

    console.log("User is idle.");
  };

  const handleOnClose = () => {
    setOpen(false);
    clearTimeout(sessionTimeoutRef.current);
  };

  const handleOnLogout = async () => {
    try {
      handleOnClose();
      await logout();
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Unable to logout", {
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        variant: "error",
      });
    }

    clearTimeout(sessionTimeoutRef.current);
  };

  // Timeout
  const [userTimeout, setUserTimeout] = useState(false);

  const handleOnTimeout = () => {
    setUserTimeout(true);

    document.cookie =
      "auth0.is.authenticated=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie =
      "_legacy_auth0.is.authenticated=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    clearTimeout(sessionTimeoutRef.current);

    console.log("User timed out.");
  };

  const handleOnLogin = () => {
    window.location.reload();

    clearTimeout(sessionTimeoutRef.current);
  };

  useIdleTimer({
    // 1800 * 1000 = 1.8 million milliseconds = 30 mins
    timeout: 1800 * 1000,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <>
      {!userTimeout && (
        <div>
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Your session is about to end
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You've been inactive for a while. For your security, we'll
                automatically sign you out in approximately 1 minute. Choose
                "Stay signed in" to continue or "Sign out" if you're done.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleOnLogout}
                variant="outlined"
                sx={{
                  mx: 1,
                  mb: 1,
                }}
              >
                Sign out
              </Button>
              <Button
                onClick={handleOnClose}
                variant="contained"
                sx={{
                  mx: 2,
                  mb: 1,
                }}
              >
                Stay signed in
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}

      {userTimeout && (
        <div>
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Session expired</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your session has timed out. Please log in again.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleOnLogin}
                variant="contained"
                sx={{
                  mx: 2,
                  mb: 1,
                }}
              >
                Sign in again
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
}

export default UserActivity;
