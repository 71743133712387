import { createContext, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { auth0Config } from '../config';
var request = require("request");

let auth0Client = null;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  token: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, token } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      token
    };
  },
  LOGIN: (state, action) => {
    const { user, token } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      token
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    token: null
  })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'Auth0',
  loginWithPopup: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        auth0Client = new Auth0Client({
          redirectUri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_API_IDENTIFIER,
          ...auth0Config
        });

        await auth0Client.checkSession();

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          const token = await auth0Client.getTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_IDENTIFIER
          });

          var options = { 
            method: 'POST',
            url: `${process.env.REACT_APP_AUTH0_DOMAIN_EXPRESS_M2M}/oauth/token`,
            headers: { 'content-type': 'application/json' },
            body: `{"client_id":"${process.env.REACT_APP_AUTH0_CLIENT_ID_EXPRESS_M2M}","client_secret":"${process.env.REACT_APP_AUTH0_CLIENT_SECRET_EXPRESS_M2M}","audience":"${process.env.REACT_APP_AUTH0_DOMAIN_EXPRESS_M2M}/api/v2/","grant_type":"client_credentials"}`
          };

          request(options, function (error, response, body) {
            if (error) throw new Error(error);
              // console.log('body:', body);
              // console.log('response:', response);
          });

          // Here you should extract the complete user profile to make it
          // available in your entire app.
          // The auth state only provides basic information.

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: {
                id: user.sub,
                avatar: user.picture,
                email: user.email,
                name: user.name,
                plan: 'Premium'
              },
              token: token
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              user: null,
              token: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            token: null
          }
        });
      }
    };

    initialize();
  }, []);

  const loginWithPopup = async (options) => {
    await auth0Client.loginWithPopup(options);

    const isAuthenticated = await auth0Client.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0Client.getUser();
      const token = await auth0Client.getTokenSilently();

      // Here you should extract the complete user profile to make it available in your entire app.
      // The auth state only provides basic information.

      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: user.name,
            plan: 'Premium'
          },
          token: token
        }
      });
    }
  };

  const logout = () => {
    auth0Client.logout();
    dispatch({
      type: 'LOGOUT'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Auth0',
        loginWithPopup,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
